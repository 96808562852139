import React, { Component } from 'react';
import Section1 from './Section1/section1';

const HomePage = () => {
	return (
		<>
			<Section1 />
		</>
	)
}

export default HomePage;